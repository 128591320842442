.experience {
    max-width: 1400px;
    margin: auto;
}

.experience .title {
    text-align: center;
    margin-top: 48px;
    /* Reduce on mobile */
}

.experience .body {}

.experience .body .primary h3 {
    margin: 0px;
}

.experience .body .primary p {
    margin-top: 4px;
}

.experience .body .personal-contribution {
    margin-top: 8px;
}

.experience .body button {
    margin-top: 4px;
    padding-left: 0px;
}

.experience .body .org {
    margin-bottom: 16px;
}

.experience .body .tech-stack {
    margin-top: 8px;
}

.experience .body .tech-stack div {
    margin: 2px;
}