.introduction {
    text-align: center;
    max-width: 1440px;
    margin: auto !important;
}

.introduction>div {
    margin: auto;
    padding: 32px;
}


.introduction .photo img {
    border-radius: 50%;
    justify-content: center;
    max-height: 250px;
    /* For testing only */
    background-color: green;
}

.introduction .heading {
    /* For testing only */
    /* background-color: yellow; */
    text-align: left;
}

.introduction .heading .name {
    margin-bottom: 0px;
}

.introduction .heading .desig {
    margin-top: 0px;
}

.introduction .company {
    /* For testing only */
    /* background-color: orange; */
    display: flex;
    flex-direction: column;
}

.introduction .company>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.introduction .company>div>div {
    display: flex;
    flex-direction: column;
}

.introduction .company p {
    margin: 0px;
    text-align: left;
    margin-left: 16px;
}

.introduction .company .edu {
    margin-top: 16px;
}

.introduction .company svg {
    font-size: 1.5em;
}