.header-desktop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 16px;
}

.header-desktop-icon {
    margin: 16px;
}

.header-desktop-icon svg {
    color: black;
    font-size: 2em;
}