.certification {
    max-width: 1400px;
    margin: auto;
    text-align: center;
}

.certification .title {
    text-align: center;
    margin-top: 48px;
    /* Reduce on mobile */
}

.certification .certificate-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.certification .certificate-list .certificate {
    margin: 16px;
    /* height: 200px; */
    width: 350px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.certification .certificate-list .certificate img {
    height: 96px;
    width: 96px;
}

.certification .certificate-list .certificate .info {
    padding: 8px;
}

.certification .certificate-list .certificate .info .title {
    font-size: 1em;
    margin: 0px;
    text-align: left;
}