.skill .title{
    text-align: center;
    margin-top: 48px; /* Reduce on mobile */
}

.skill .tab {
    text-transform: inherit;
    flex-basis: 0;
}

.skill .panel {
    text-align: center;
}

.skill .panel > div{
    margin: 4px;
}