.designation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.designation h3,
p {
    margin: 0px;
}

.designation .icon {
    margin-left: 24px;
}

.designation .period {
    color: grey;
    font-style: italic;
}